import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProjectSiteSimple from '../../components/project_site_simple'
import ProjectLinkCard from '../../components/project_link_card'

const Tafor = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('sga-title')} language={i18n.language} />
          <ProjectSiteSimple title="TAFOR - Traffic Generator for Complex Telecommunication Systems" text={t('news-tafor-project-desc')} pic="szechenyi-terv">
              <ProjectLinkCard title="Press release" path="/projects/tafor-press-release.pdf" />
              <ProjectLinkCard title="Invitation to the project closing event" path="/projects/tafor-closing-invitation.pdf" />
              <ProjectLinkCard title="Technical presentation" path="/projects/tafor-technical-presentation.pdf" />
              <ProjectLinkCard title="Product leaflet" path="/products/sga-tg.pdf" />
          </ProjectSiteSimple>
      </Layout>
  )
}

export default Tafor

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "sga", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
